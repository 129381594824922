import React from 'react';

const About = () => {
  return (
    <div name='about' className='w-full h-screen bg-[#000000] text-gray-300'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
          <div className='sm:text-right pb-8 pl-4'>
            <p className='text-4xl font-bold inline border-b-4 border-orange-600'>
              About
            </p>
          </div>
          <div></div>
          </div>
          <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
            <div className='sm:text-right text-4xl font-bold'>
              <p>The name's Dheeraj. Feel free to take a look around.</p>
            </div>
            <div>
              <p>CS Student at The University of Texas at Austin, slated to graduate in May 2024. I've honed my skills on data structures and algorithms with mainstream languages to ensure maximum optimization when solving problems.</p>
              <p> <br/> </p>
              <p>I then delved into front-end by self-teaching languages (JS), libraries (React), and frameworks (Tailwind CSS) to bring ideas to life. </p> 
              <p> <br/> </p>
              <p>Currently learning tenets of AI/ML through implementing projects via Python. </p>  
              <p> <br/> </p>
            </div>
          </div>
      </div>
    </div>
  );
};

export default About;
