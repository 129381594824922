import React from 'react';
import coming_soon3 from '../assets/coming_soon3.png';
import os from '../assets/ospic.png'
import ai from '../assets/ai.jpeg'

const Work = () => {
  return (
    <div name='work' className='w-full md:h-screen text-gray-300 bg-[#000000]'>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div className='pb-8'>
          <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-orange-600'>
            Work
          </p>
          <p className='py-6'>Here's a list of projects that I've completed as well as ones that I'm planning to create. Projects will be released upon completion.</p>
        </div>

{/* Container */}
        <div className='grid sm:grid-cols-2 md:grid-cols-2 gap-4'>

            {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${os})` }}
            className='shadow-lg shadow-[#160904] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 text-center'>
              <span className='text-2xl font-bold text-white tracking-wider'>
                DK Operating System
              </span>
              <div className='pt-8 text-center'>
                <a href='https://gitfront.io/r/user-1015360/zjwGGTzw8YRy/DK-OS/'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          
          <div
            style={{ backgroundImage: `url(${ai})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <span className='text-2xl font-bold text-white tracking-wider'>
                Python Face Detector
              </span>
              <div className='pt-8 text-center'>
              <a href='https://github.com/dkash07/OpenCV-Facial-Recognition'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>

            {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${coming_soon3})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <span className='text-2xl font-bold text-white tracking-wider'>
                CRUD Application (Pending)
              </span>
              <div className='pt-8 text-center'>
              </div>
            </div>
          </div>

          <div
            style={{ backgroundImage: `url(${coming_soon3})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <span className='text-2xl font-bold text-white tracking-wider'>
                Algorithm Visualizer (Pending)
              </span>
              <div className='pt-8 text-center'>
              </div>
            </div>
          </div>

           
        </div>
      </div>
    </div>
  );
};

export default Work;
